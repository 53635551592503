{
  "baseFooter": {
    "rights": "판권 소유"
  },
  "baseHeader": {
    "menu": {
      "account": "계정",
      "language": "언어: {currentLanguage}",
      "logout": "로그아웃",
      "manage_following": "구독 관리",
      "my_clips_and_highlights": "클립&하이라이트",
      "select_language": "언어 선택",
      "selected_videos": "북마크 영상"
    }
  },
  "button": {
    "back": "뒤로가기",
    "buy_now": "구매",
    "cancel": "취소",
    "clear_all": "전체 삭제",
    "confirm": "확인",
    "delete": "삭제",
    "download": "다운로드",
    "edit": "편집",
    "enter_another_card": "다른카드",
    "enter_another_code": "다른코드",
    "fullscreen_enter": "전체 화면",
    "fullscreen_exit": "전체 화면 나가기",
    "go_to_homepage": "홈페이지",
    "no": "NO",
    "refresh": "새로고침",
    "remove": "삭제",
    "submit_code": "서브코드",
    "toggle_dark": "DARK",
    "toggle_langs": "LANGS",
    "try_again": "재시도",
    "slide_back": "이전 슬라이드",
    "slide_forward": "다음 슬라이드",
    "exit": "나가기",
    "full": "전체화면",
    "regular": "줌화면",
    "rename": "이름 바꾸기",
    "get": "얻다"
  },
  "common": {
    "data_not_found": "영상을 찾을수 없음",
    "empty_list": "목록이 없음",
    "empty_search": "검색 결과 없음",
    "loading": "로딩중",
    "loading_failed": "로딩 실패",
    "empty_events_list": "아직 이벤트가 없습니다",
    "get_mobile_app": "최고의 경험을 얻으세요 \n{appName}"
  },
  "countries": {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AC": "Ascension Island",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "IC": "Canary Islands",
    "BQ": "Caribbean Netherlands",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "EA": "Ceuta and Melilla",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CV": "Cape Verde",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DG": "Diego Garcia",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IL": "Israel",
    "IM": "Isle of Man",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "XK": "Kosovo",
    "KI": "Kiribati",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MK": "North Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PN": "Pitcairn Islands",
    "PH": "Philippines",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RO": "Romania",
    "RW": "Rwanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SO": "Somalia",
    "SB": "Solomon Islands",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SZ": "Eswatini",
    "SE": "Sweden",
    "CH": "Switzerland",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TH": "Thailand",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "WF": "Wallis and Futuna",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "VG": "British Virgin Islands",
    "BN": "Brunei",
    "CD": "Congo, Dem. Rep. of (Zaire)",
    "CW": "Curacao",
    "TL": "Timor-Leste",
    "HK": "Hong Kong SAR China",
    "CI": "Côte d'Ivoire",
    "LA": "Laos",
    "MO": "Macau SAR China",
    "KP": "North Korea",
    "PS": "Palestinian Territory",
    "RE": "Reunion",
    "RU": "Russia",
    "KR": "South Korea",
    "SY": "Syria",
    "TZ": "Tanzania",
    "VI": "U.S. Virgin Islands",
    "GB": "United Kingdom",
    "US": "United States",
    "VA": "Vatican City",
    "VN": "Vietnam",
    "EH": "Western Sahara"
  },
  "errors": {
    "expired_session_error": "기간이 만료되었습니다. \n다시 로그인하십시오.",
    "could_not_find_event": "이벤트를 찾을수 없습니다",
    "could_not_find_page": "페이지를 찾을수 없습니다",
    "could_not_find_team": "팀을 찾을수 없습니다",
    "event_not_found": "이벤트를 찾을수 없습니다",
    "page_not_found": "페이지를 찾을수 없습니다",
    "team_not_found": "팀을 찾을수없습니다",
    "could_not_find_results": "죄송합니다. 이 검색어에 대한 결과를 찾을 수 없습니다.",
    "ERR400CL11": "클립을 만들지 못했습니다.",
    "ERR400CM11": "결제 실패",
    "ERR400CM12": "주문 실패",
    "ERR400CM13": "캡처 결제 실패",
    "ERR400CM14": "구독 가맹점 정보 저장 실패",
    "ERR400CM15": "신용 카드 정보를 확인하십시오",
    "ERR400CM16": "잘못된 플랫폼 값이 제공되었습니다.",
    "ERR400GN00": "잘못된 요청",
    "ERR400GN11": "잘못된 ID가 제공되었습니다.",
    "ERR400GN12": "업데이트할 수 없는 필드가 제공됨",
    "ERR400ID11": "잘못된 ID가 제공되었습니다.",
    "ERR400ID12": "잘못된 ID 항목이 제공됨",
    "ERR400ID13": "클럽 목록에 ID를 추가할 수 없습니다.",
    "ERR400ID14": "잘못된 ID 쌍과 ID 항목이 제공되었습니다.",
    "ERR400TG11": "태그를 만들지 못했습니다.",
    "ERR400UR11": "프로필 이름과 성은 필수 항목입니다.",
    "ERR400UR12": "이메일이 필요합니다",
    "ERR400UR13": "사용자는 이미 저장된 신용 카드를 가지고 있습니다.",
    "ERR400UR14": "신용 카드를 저장하기 전에 프로필의 이름, 성 및 이메일 필드를 작성하십시오.",
    "ERR400UR17": "사용자에게 수정할 신용카드가 없습니다.",
    "ERR400UR18": "구독이 이미 취소되었습니다.",
    "ERR400UR19": "구독이 활성화되지 않았습니다.",
    "ERR400UR20": "사용자 이름과 암호가 필요합니다",
    "ERR400UR21": "계정이 활성화되지 않았습니다.",
    "ERR401AT11": "토큰이 필요합니다",
    "ERR401AT12": "잘못된 암호 재설정 토큰",
    "ERR401AT13": "잘못된 갱신 토큰",
    "ERR401AT14": "알 수 없는 주제 발급자",
    "ERR401AT15": "지원되지 않는 제목 토큰 유형",
    "ERR401AT16": "잘못된 토큰",
    "ERR401AT17": "만료된 토큰",
    "ERR401AT18": "토큰에서 이메일을 가져오지 못했습니다.",
    "ERR401AT19": "사용자를 찾을 수 없음",
    "ERR401GN00": "무단",
    "ERR402GN00": "결제 필요",
    "ERR403GN00": "금지",
    "ERR403TG11": "사용자는 전역 태그를 업데이트할 수 없습니다.",
    "ERR403TG12": "사용자는 전역 태그를 생성할 수 없습니다.",
    "ERR403TG13": "사용자가 생성하지 않은 태그는 삭제할 수 없습니다.",
    "ERR403TG14": "사용자는 전역 태그를 삭제할 수 없습니다.",
    "ERR404CM11": "구독을 받을 수 없습니다.",
    "ERR404CM12": "액세스 코드를 찾을 수 없음",
    "ERR404EV11": "이벤트 정보를 가져올 수 없습니다.",
    "ERR404EV12": "이벤트 또는 VOD를 찾을 수 없음",
    "ERR404GN00": "찾을 수 없음",
    "ERR404ID00": "ID를 찾을 수 없음",
    "ERR404ID11": "ID를 검색할 수 없습니다.",
    "ERR404PR00": "제품을 찾을 수 없음",
    "ERR404PR11": "ppv 정보를 얻을 수 없습니다",
    "ERR404TG00": "태그를 찾을 수 없음",
    "ERR404TM00": "팀을 찾을 수 없음",
    "ERR404UR00": "사용자를 찾을 수 없음",
    "ERR404UR11": "주문을 찾을 수 없습니다.",
    "ERR404UR12": "구독을 찾을 수 없습니다.",
    "ERR404UR13": "사용자가 존재하지 않습니다",
    "ERR404UR14": "프로필이 존재하지 않습니다",
    "ERR404UR15": "이 사용자의 카드를 찾을 수 없습니다.",
    "ERR404UR16": "관심 목록에서 동영상을 찾을 수 없음",
    "ERR404VD11": "VOD를 검색할 수 없습니다.",
    "ERR409CM11": "이미 사용 중인 액세스 코드",
    "ERR409GN00": "갈등",
    "ERR409PR12": "이미 구매한 제품",
    "ERR409UR11": "클럽이 이미 추가되었습니다.",
    "ERR409UR12": "이미 등록된 사용자",
    "ERR422GN00": "처리할 수 없는 콘텐츠",
    "ERR451GN00": "법적인 이유로 사용할 수 없음",
    "ERR500GN00": "인터넷 서버 오류",
    "ERR500NT12": "비밀번호 재설정 이메일 전송 실패",
    "ERR501GN00": "구현되지 않음",
    "ERR502GN00": "잘못된 게이트웨이",
    "ERR503GN00": "서비스 불가",
    "ERR504GN00": "게이트웨이 시간 초과",
    "ERR401UR11": "사용자 이름 또는 암호가 잘못되었습니다. \n자격 증명을 확인하십시오.",
    "could_not_find_league": "죄송합니다. 원하시는 리그를 찾을 수 없습니다.",
    "default_error_message": "서버 오류가 발생했습니다. \n나중에 다시 시도 해주십시오",
    "device_limit_exceeded": "장치 한도 초과!",
    "league_not_found": "리그를 찾을 수 없음",
    "vod_corrupted_files": "손상된 VOD 파일",
    "vod_files_not_found": "찾을수 없는 VOD 파일",
    "vod_playback_failed": "VOD 재생 실패",
    "passwords_dont_match": "비밀번호가 일치하지 않습니다",
    "incomplete_cvc": "카드의 보안 코드가 완전하지 않습니다!",
    "incomplete_expiry": "카드의 만료일이 불완전합니다!",
    "incomplete_number": "카드 번호가 완전하지 않습니다!",
    "invalid_expiry_month_past": "카드의 만료 연도가 과거입니다!",
    "invalid_expiry_year": "카드 만료 연도가 잘못되었습니다!",
    "invalid_expiry_year_past": "카드의 만료 연도가 과거입니다!",
    "invalid_number": "카드 번호가 잘못되었습니다!",
    "popup_closed": "팝업이 닫혔습니다",
    "login_failed": "로그인 실패",
    "ERR400UR24": "이전 비밀번호가 잘못되었습니다. \n자격증명을 확인해주세요",
    "live_stream_error_message": "이런! \n약간의 지연이 있는 것 같습니다. \n우리 팀은 가능한 한 빨리 문제를 해결하기 위해 적극적으로 노력하고 있습니다. \n양해해 주셔서 감사합니다!"
  },
  "footer_links": {
    "about_us": "회사 소개",
    "custom": "{custom}",
    "faq": "FAQ",
    "privacy_policy": "개인정보처리방침",
    "terms_of_service": "이용약관"
  },
  "labels": {
    "access_code": "인증 코드",
    "access_time": "인증 시간",
    "active": "활성화",
    "add_to_selected": "선택항목추가",
    "all": "전체",
    "all_results": "전체결과",
    "amount": "금액",
    "anytime": "언제나",
    "bookmark_add": "북마크 추가",
    "bookmark_remove": "북마크 삭제",
    "change_product": "제품변경",
    "clip": "클립",
    "commerce_category_labels": {
      "free": "무료",
      "member": "멤버",
      "ppv": "개별영상 결제",
      "premium": "프리미엄",
      "rental": "대여",
      "subscription": "구독"
    },
    "lock_event_message": {
      "geo_blocking": "죄송합니다. 재생하려는 동영상은 해당 지역에서 제한되어 있습니다.",
      "event_restricted": "이 이벤트는 귀하의 위치에서 제한됩니다",
      "premium": "시청 구독",
      "member": "게임을 시청하려면 로그인하세요.",
      "subscription": ""
    },
    "confirm_new_password": "새로운 비밀번호 입력",
    "continue": "계속",
    "continue_with": "{provider}계속",
    "country": "국가",
    "create_highlight": "하이라이트 생성",
    "dark": "어둡게",
    "date": "날짜",
    "date_added": "날짜 추가",
    "days": "일",
    "email": "이메일",
    "enter_new_password": "새로운 비밀번호",
    "event_date": "이벤트 날짜",
    "events": "이벤트",
    "follow": "구독",
    "following": "구독",
    "forgot_password": "비밀번호찾기",
    "full_access": "풀 액세스",
    "full_name": "이름",
    "game_highlights": "경기하이라이트",
    "game_videos": "경기영상",
    "go_to_account": "계정",
    "go_to_event": "이벤트",
    "go_to_main_page": "홈페이지",
    "highlights": "하이라이트 | 하이라이트",
    "hours": "시",
    "inactive": "비활성",
    "latest": "최근",
    "latest_events": "최근영상",
    "latest_highlights": "최근하이라이트",
    "light": "밝게",
    "limited_access": "제한된 액세스",
    "live": "라이브",
    "load_more": "더보기",
    "main": "메인",
    "minutes": "분",
    "modal_labels": {
      "delete_clip": "클립 삭제",
      "delete_highlight": "하이라이트 삭제",
      "delete_profile": "프로파일 삭제",
      "delete_tag": "태그 삭제"
    },
    "months": "월",
    "my_club": "마이클럽",
    "name": "이름",
    "newest": "최근",
    "next": "다음",
    "oldest": "최후",
    "or": "또는",
    "page_not_found": "찾을수 없음",
    "password": "비밀번호",
    "password_new": "새로운 비밀번호",
    "password_old": "현재비밀번호",
    "password_repeat": "비밀번호 확인",
    "past_30_days": "과거 30일",
    "past_7_days": "과거 7일",
    "past_90_days": "과거90일",
    "phone": "전화번호",
    "player_highlights": "플레이어 하이라이트",
    "policy": "정책",
    "powered_by": "powered by",
    "ppv_price": "{price}원",
    "ppv_tickets": "PPV티켓",
    "purchase": "구매",
    "release_date": "업데이트일정",
    "remove_from_selected": "선택한 항목삭제",
    "save": "저장",
    "search": "검색",
    "search_input_placeholder": "태그",
    "seconds": "초",
    "see_all": "더보기",
    "select_your_club": "클럽 선택",
    "share": "공유",
    "sign_in": "로그인",
    "sign_up": "회원가입",
    "skip": "스킵",
    "sport_type": "스포츠 종류",
    "statistics": "통계",
    "submit": "제출",
    "subscriptions": "구독",
    "tag": "태그",
    "terms": "조건",
    "today": "오늘",
    "total": "합계",
    "type": "타입",
    "upcoming": "예정",
    "update": "업데이트",
    "update_card": "카드 업데이트",
    "views": "뷰",
    "watch_full_game": "전체경기 보기",
    "weeks": "주",
    "years": "연도",
    "yesterday": "어제",
    "your_clips_highlight": "하이라이트",
    "password_not_match": "비밀번호가 일치하지 않습니다",
    "search_by_sport_type": "스포츠 유형으로 검색",
    "videos": "비디오",
    "category": "범주",
    "reset_password": "새 비밀번호를 입력하세요",
    "min_8_characters": "최소 8자",
    "league": "리그",
    "here": "여기",
    "suggested": "제안",
    "enter_old_password": "현재 비밀번호 입력",
    "less": "줄이기",
    "more": "더보기",
    "durationRenew": "기간: 구매 후 {duration} {units} 갱신됨 | \n기간: {duration}에 갱신됩니다.",
    "duration": "기간: 구매 후 {duration} {units} 만료 | \n기간: 만료 {duration}",
    "team": "팀",
    "team_1_logo": "팀 1 로고",
    "team_2_logo": "팀 2 로고",
    "stats": "통계",
    "gameType_labels": {
      "game": "게임",
      "other": "기타",
      "practice": "연습",
      "training": "훈련"
    },
    "fullName": "성명",
    "archive": "보관소",
    "panoramic_mode": "현재 파노라마 모드입니다.",
    "postal_code": "우편 번호",
    "search_event_league_team": "이벤트, 리그 또는 팀 검색",
    "back_to_site": "사이트로 돌아가기",
    "done": "완료",
    "search_teams": "검색",
    "game_recap": "게임 요약",
    "play_by_play": "실황",
    "your_clips": "귀하의 클립",
    "gender": "성별",
    "gender_labels": {
      "female": "여성",
      "male": "남성",
      "mixed": "남녀공학"
    }
  },
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portuguese",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ca": "Catalan",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "타이틀"
  },
  "not-found": "찾을수 없음",
  "pages": {
    "account": {
      "account_delete_button": "계정삭제",
      "account_delete_confirm": "계정삭제확인",
      "account_delete_description": "계정이 삭제됩니다",
      "tab_billing_title": "결제",
      "tab_devices_title": "디바이스",
      "tab_general_title": "일반",
      "tab_password_title": "패스워드",
      "tab_ppv_access_text": "PPV액세스",
      "tab_ppv_no_ppv": "PPV가 없음",
      "tab_ppv_title": "PPV정보",
      "tab_subscription_title": "구독",
      "account_delete_successfully": "귀하의 계정이 성공적으로 삭제되었습니다!",
      "account_update_successfully": "귀하의 계정이 성공적으로 업데이트되었습니다!"
    },
    "account_billing": {
      "alert_credit_card_save_success": "신용카드 저장 성공",
      "alert_credit_card_update_success": "신용카드 업데이트 성공",
      "button_back_to_billing_info": "결제정보 확인",
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "카드 만료일",
      "card_holder_name": "카드 소지자",
      "card_number": "카드번호",
      "label_modal_confirm_credit_card_delete": "신용카드 삭제 확인",
      "payment_history_empty_text": "결제 이력 없음",
      "payment_history_title": "결제이력",
      "alert_credit_card_delete_success": "청구 카드가 성공적으로 삭제되었습니다.",
      "payment_in_progress": "결제 진행 중",
      "payment_in_progress_message": "결제가 처리될 때까지 이 페이지를 닫지 마세요."
    },
    "account_password": {
      "alert_password_update_success": "비밀번호 업데이트 완료"
    },
    "account_subscription": {
      "active_subscription": "구독 활성화",
      "alert_access_code_redeem_code_used": "액세스 코드가 이미 사용된 코드입니다",
      "alert_access_code_redeem_not_found": "액세스 코드를 찾을수 없습니다",
      "alert_access_code_redeemed_success": "액세스 코드 사용 성공",
      "alert_subscription_cancel_success": "구독 취소 성공",
      "button_back_to_my_subscription": "나의 구독",
      "button_back_to_my_tickets": "나의 티켓",
      "button_subscribe_now": "유료구독",
      "button_subscription_cancel": "유료구독취소",
      "button_subscription_keep": "유료구독유지",
      "cancel_subscription_modal_subtitle": "구독취소",
      "cancel_subscription_modal_title": "구독취소",
      "checkbox_apply_as_new_credit_card": "신규신용카드적용",
      "label_full_access": "전체권한",
      "label_full_access_description": "전체권한 설명",
      "label_limited_access": "제한된 엑세스",
      "label_limited_access_description": "제한된 액세스 설명",
      "label_plan_amount": "금액",
      "label_taxes": "세금",
      "label_total_billed": "총청구액",
      "list_subscriptions_empty": "구독없음",
      "no_subscriptions_text": "구독없음",
      "or_select_plan": "선택계획",
      "payment_details": "{title}:결제상세내역",
      "redeem_access_code_label": "액세스코드라벨",
      "redeem_access_code_title": "액세스코드타이틀",
      "subscription_due_date": "가입 기한",
      "subscription_expiration_date": "가입만료일",
      "subscription_expires": "가입만료 {date}",
      "subscription_last_payment_date": "최종 결제일",
      "subscription_next_charge_date": "다음 결제일",
      "tab_all_subscriptions": "전체구독",
      "tab_my_subscriptions": "나의구독",
      "renew_subscription_expires": "구독은 반복됩니다. \n다음 청구 날짜 {date}"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "승인된 디바이스가 아닙니다"
    },
    "auth_login_limit": {
      "current_device": "현재 장치",
      "device_limit_reached": "장치 제한",
      "remove_device_to_login": "로그인하려면 장치를 제거해 주세요"
    },
    "auth_password": {
      "back_to_sign_in": "로그인창으로 가기",
      "forgot_password_help_text": "비밀번호찾기",
      "forgot_password_success_text": "비밀번호찾기 성공",
      "resend_instruction": "비밀번호 지침 재송부",
      "send_reset_link": "리셋 메시지 발송",
      "set_new_password": "새로운 비밀번호 설정"
    },
    "auth_sign_in": {
      "auth_terms": "계속 진행시, 당사의 {terms_of_service} 및 {privacy_policy}에 동의하시는 것으로 간주됩니다.",
      "new_to": "새로운",
      "welcome_message": "안녕하세요 \n HOGAK입니다",
      "sign_in_with_provider": "{provider} 자격 증명으로 로그인하세요."
    },
    "auth_sign_up": {
      "already_have_account": "계정이 이미 존재합니다",
      "enter_your_details_below": "아래항목을 자세히 적어주세요",
      "follow_category_header": "카테고리 머리글 따르기",
      "password_updated_successuly_text": "성공적으로 비밀번호가 업데이트 되었습니다",
      "back_to_all_teams": "모든 팀으로 돌아가기",
      "follow_teams_header": "구독하고 싶은 팀을 선택하세요",
      "back_to_all_categories": "모든 카테고리로 돌아가기"
    },
    "clips": {
      "title_no_clips": "클립 영상이 없습니다",
      "title_no_highlights": "하이라이트 영상이 없습니다",
      "title_no_items_with_selected_filter": "선택한 항목 없음"
    },
    "club": {
      "favorite_club_creating_successfully_text": "관심 클럽 생성이 완료되었습니다."
    },
    "clubs": {
      "choose_your_club": "클럽을 선택하세요",
      "choose_your_club_message": "클럽을 선택하세요",
      "manage_following": "구독 관리"
    },
    "events": {
      "appreciation": "보기",
      "event_start_date": "이벤트 시작 날짜",
      "event_will_start_in_a_few_min": "몇분후에 이벤트가 시작됩니다",
      "failed_already_used_code": "이미 사용중인 코드입니다",
      "failed_code_applied_message": "코드 적용이 실패하였습니다",
      "failed_code_applied_title": "코드 승인 실패",
      "failed_purchased_message": "구매에 실패하였습니다",
      "failed_statistics": "통계자료실패",
      "game_video_login_message": "로그인",
      "modal_share_title": "공유",
      "modal_share_with_time": "{time}공유",
      "player_errors": {},
      "purchased_declined": "구매가 취소되었습니다",
      "submit_reedem_code_input_placeholder": "상품권입력",
      "success_code_applied": "{title} 코드적용완료",
      "success_purchased": "{subscription} 구매완료",
      "tabs": {
        "event_tags": {
          "choose_player_color": "선수의 색상을 선택하세요",
          "choose_tag": "태그선택",
          "create_tag": "태그생성",
          "filters": {
            "by_player_number": "선수 번호",
            "by_type": "타입",
            "clear_all_btn": "전체 삭제 {count}",
            "no_tags": "태그없음",
            "popup_title": "팝업제목",
            "search_input_placeholder": "검색어입력",
            "select_all": "전체선택 {count}",
            "show_results_btn": "결과 보기",
            "by_team": "팀별"
          },
          "is_external_content": "외부 컨텐츠",
          "make_tag_global": "태그생성",
          "name_the_tag": "태그 이름",
          "pause_all_btn": "모두 일시 정지",
          "play_all_btn": "전체보기",
          "tag_creating_successfully_text": "태그 생성 성공",
          "tag_deleting_successfully_text": "태그 삭제 성공",
          "tag_updating_successfully_text": "태그 업데이트 성공",
          "title_enabled_tagging": "태그 활성화",
          "title_no_tags": "태그 없음",
          "title_no_tags_not_logged_in": "로그인하지 않은 태그 없음",
          "update_tag": "태그 업데이트",
          "choose_team": "팀을 선택하세요"
        },
        "event_videos": {
          "clip_creating_successfully_text": "클립이 성공적으로 생성되었습니다",
          "create_clip": "클립 생성",
          "name_the_clip": "클립 이름",
          "title_enabled_clipping": "클립핑 활성화",
          "title_no_clips": "클립 없음",
          "title_no_clips_not_logged_in": "로그인하지 않은 클립 없음",
          "clip_deleted_successfully_text": "클립이 삭제되었습니다.",
          "rename_clip": "클립 이름 바꾸기",
          "clip_updating_successfully_text": "클립이 성공적으로 업데이트되었습니다."
        },
        "event_stats": {
          "empty_stats_data": "통계 데이터가 비어 있습니다.",
          "game_board_title": "게임 통계",
          "score_board_title": "점수"
        }
      },
      "tags_login_message": "로그인",
      "viewing_conditions": "감상조건",
      "watching_event_by_text": "이벤트보기",
      "success_purchased_secondary": "구매완료",
      "time_till_kick_off": "킥오프까지 남은 시간:",
      "failed_purchased_message_2": "다시 시도하거나 다른 결제 수단을 사용해 주세요."
    },
    "league": {
      "all_rounds": "전체라운드"
    },
    "videos": {
      "description_no_items": "아이템 없음",
      "last_viewed": "최근 영상",
      "title_no_items": "아이템 없음",
      "user_interesting": "추천영상"
    },
    "categories": {
      "no_following_category_for_logged_user": "구독 영상이 없습니다.최신영상보기{here}",
      "no_following_category_for_unlogged_user": "최신영상보기 {login}"
    }
  },
  "sport_types": {
    "americanFootball": "미식축구",
    "badminton": "배드민턴",
    "baseball": "야구",
    "basketball": "농구",
    "beachvolleyball": "비치발리볼",
    "bowling": "볼링",
    "cricket": "크리켓",
    "fieldhockey": "필드하키",
    "floorball": "플로어볼",
    "futsal": "풋살",
    "gymnastics": "체조",
    "handball": "핸드볼",
    "icehockey": "아이스하키",
    "lacrosse": "라크로스",
    "netball": "네트볼",
    "other": "기타",
    "rollerhockey": "롤러하키",
    "rugby": "럭비",
    "snooker": "포켓볼",
    "soccer": "축구",
    "softball": "소프트볼",
    "swimming": "수영",
    "tennis": "테니스",
    "volleyball": "배구",
    "waterpolo": "수구",
    "wrestling": "레슬링",
    "football": "축구",
    "flagfootball": "플래그 풋볼"
  },
  "tags": {
    "other_wow": "other wow",
    "general_bad": "bad",
    "general_good": "good",
    "general_drill": "drill",
    "basketball_dunk": "dunk",
    "basketball_three_point": "three point",
    "basketball_big_play": "big play",
    "basketball_alley_oop": "alley oop",
    "basketball_assist": "assist",
    "basketball_block": "block",
    "basketball_momentym": "momentum",
    "basketball_money_time": "money time",
    "basketball_substitution": "substitution",
    "soccer_goal": "goal",
    "soccer_penalty": "penalty",
    "soccer_shot_on_target": "shot on target",
    "soccer_yellowcard": "yellow card",
    "soccer_redcard": "red card",
    "soccer_foul": "foul",
    "soccer_corner_kick": "corner kick",
    "soccer_shot": "shot",
    "soccer_free_kick": "free kick",
    "soccer_header": "header",
    "soccer_save": "save",
    "soccer_substitution": "substitution",
    "football_touch_down": "touch down",
    "football_field_goal": "field goal",
    "football_big_catch": "big catch",
    "football_big_run": "big run",
    "football_big_hit": "big hit",
    "football_kick_return": "kick return",
    "football_punt_return": "punt return",
    "football_kickoff": "kickoff",
    "football_substitution": "substitution",
    "rugby_break_down": "break down",
    "rugby_conversion": "conversion",
    "rugby_drop_goal": "drop goal",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "grubber",
    "rugby_knock_on": "knock on",
    "rugby_maul": "maul",
    "rugby_penalty_try": "penalty try",
    "rugby_substitution": "substitution",
    "volleyball_free_ball": "free ball",
    "volleyball_side_out": "side out",
    "volleyball_spike": "spike",
    "volleyball_dig": "dig",
    "volleyball_double": "double",
    "volleyball_mishit": "mishit",
    "volleyball_shank": "shank",
    "volleyball_serve": "serve",
    "volleyball_set_point": "set point",
    "volleyball_match_point": "match point",
    "volleyball_substitution": "substitution",
    "baseball_stolen_base": "stolen base",
    "baseball_home_run": "home run",
    "baseball_run": "run",
    "baseball_single": "single",
    "baseball_hit": "hit",
    "baseball_double": "double",
    "baseball_tripple": "tripple",
    "baseball_strikeout": "strikeout",
    "baseball_popout": "popout",
    "baseball_ground_out": "ground out",
    "baseball_fly_out": "fly out",
    "baseball_double_play": "double play",
    "baseball_triple_play": "triple play",
    "baseball_walk": "walk",
    "baseball_top_1st": "top 1st",
    "baseball_bottom_1st": "bottom 1st",
    "baseball_top_2nd": "top 2nd",
    "baseball_bottom_2nd": "bottom 2nd",
    "baseball_substitution": "substitution",
    "lacrosse_goal": "goal",
    "lacrosse_shot": "shot",
    "lacrosse_save": "save",
    "lacrosse_clear": "clear",
    "lacrosse_man_up": "man up",
    "lacrosse_penalty": "penalty",
    "lacrosse_face_off": "face off",
    "lacrosse_ground_ball": "ground ball",
    "lacrosse_substitution": "substitution",
    "futsal_goal": "goal",
    "futsal_penalty": "penalty",
    "futsal_shot_on_target": "shot on target",
    "futsal_yellowcard": "yellow card",
    "futsal_redcard": "red card",
    "futsal_foul": "foul",
    "futsal_corner_kick": "corner kick",
    "futsal_shot": "shot",
    "futsal_free_kick": "free kick",
    "futsal_header": "header",
    "futsal_save": "save",
    "futsal_substitution": "substitution",
    "beach_volleyball_free_ball": "free ball",
    "beach_volleyball_side_out": "side out",
    "beach_volleyball_spike": "spike",
    "beach_volleyball_dig": "dig",
    "beach_volleyball_double": "double",
    "beach_volleyball_mishit": "mishit",
    "beach_volleyball_shank": "shank",
    "beach_volleyball_serve": "serve",
    "beach_volleyball_set_point": "set point",
    "beach_volleyball_match_point": "match point",
    "beach_volleyball_substitution": "substitution",
    "field_hockey_goal": "goal",
    "field_penalty_corner": "penalty corner",
    "field_ball_possesion": "ball possesion",
    "field_ball_loss": "ball loss",
    "field_turn_over": "turn over",
    "field_circle_penetration": "circle penetration",
    "field_press_fp_hc": "press",
    "field_interception": "interception",
    "field_time_penalty": "time penalty",
    "field_hockey_fight": "fight",
    "field_hockey_big_hit": "big hit",
    "field_hockey_power_play": "power play",
    "field_hockey_4v4": "4v4",
    "field_hockey_shot": "shot",
    "field_hockey_save": "save",
    "field_hockey_other": "other",
    "field_hockey_substitution": "substitution",
    "hockey_goal": "goal",
    "hockey_fight": "fight",
    "hockey_big_hit": "big hit",
    "hockey_power_play": "power play",
    "hockey_4v4": "4v4",
    "hockey_shot": "shot",
    "hockey_save": "save",
    "hockey_substitution": "substitution",
    "roller_hockey_goal": "goal",
    "roller_penalty_corner": "corner",
    "roller_ball_possesion": "ball possesion",
    "roller_ball_loss": "ball loss",
    "roller_turn_over": "turn over",
    "roller_circle_penetration": "circle penetration",
    "roller_press_fp_hc": "press",
    "roller_interception": "interception",
    "roller_time_penalty": "time penalty",
    "roller_hockey_fight": "fight",
    "roller_hockey_big_hit": "big hit",
    "roller_hockey_power_play": "power play",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_shot": "shot",
    "roller_hockey_save": "save",
    "roller_hockey_other": "other",
    "roller_hockey_substitution": "substitution",
    "handball_goal": "goal",
    "handball_shot_on_goal": "shot on goal",
    "handball_save": "save",
    "handball_foul": "foul",
    "handball_red_card": "red card",
    "handball_yellow_card": "yellow card",
    "handball_suspension": "suspension",
    "handball_penalty_shot": "penalty shot",
    "handball_substitution": "substitution",
    "wrestling_wow": "wow",
    "gymnastic_wow": "wow"
  }
}
